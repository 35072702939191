import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
import Layout1 from '../layouts/Layout1'
import Default from '../layouts/BlankLayout'
import AuthLayout1 from '../layouts/AuthLayouts/AuthLayout1'
import AuthLayout2 from '../layouts/AuthLayouts/AuthLayout2'
/* Dashboards View */
import Dashboard4 from '../views/Dashboards/Dashboard4'
import FleetDashboard from '../views/Dashboards/FleetDashboard'
/* UIElements View */
import UiAlerts from '../views/core/UiAlerts.vue'
import UiButtons from '../views/core/UiButtons.vue'
import UiBadges from '../views/core/UiBadges.vue'
import UiBreadcrumb from '../views/core/UiBreadcrumb.vue'
import UiColors from '../views/core/UiColors.vue'
import UiTypography from '../views/core/UiTypography.vue'
import UiCards from '../views/core/UiCards.vue'
import UiCarousel from '../views/core/UiCarousel.vue'
import UiEmbedVideo from '../views/core/UiEmbedVideo.vue'
import UiGrid from '../views/core/UiGrid.vue'
import UiModal from '../views/core/UiModal.vue'
import UiListGroup from '../views/core/UiListGroup.vue'
import UiImages from '../views/core/UiImages.vue'
import UiMediaObject from '../views/core/UiMediaObject.vue'
import UiTooltips from '../views/core/UiTooltips.vue'
import UiPopovers from '../views/core/UiPopovers.vue'
import UiNotifications from '../views/core/UiNotifications.vue'
import UiTabs from '../views/core/UiTabs.vue'
import UiPagination from '../views/core/UiPagination.vue'
import UiProgressBars from '../views/core/UiProgressBars.vue'
/* Authentic View */
import SignIn1 from '../views/AuthPages/Default/SignIn1'
import SignIn2 from '../views/AuthPages/Default/SignIn2'
import SignUp1 from '../views/AuthPages/Default/SignUp1'
import RecoverPassword1 from '../views/AuthPages/Default/RecoverPassword1'
import NewPassword from '../views/AuthPages/Default/NewPassword'
import LockScreen1 from '../views/AuthPages/Default/LockScreen1'
import ConfirmMail1 from '../views/AuthPages/Default/ConfirmMail1'
import Interview from '../views/AuthPages/Default/Interview'
import ConfirmationApprovalMail from '../views/AuthPages/Default/ConfirmationApprovalMail'
import RequestSignOff from '../views/AuthPages/Default/RequestSignOff'
/* Extra Pages */
import ErrorPage from '../views/Pages/ErrorPage'
import ComingSoon from '../views/Pages/ComingSoon'
import Maintenance from '../views/Pages/Maintenance'
import TimeLine from '../views/Pages/TimeLines'
import Pricing from '../views/Pages/Pricing'
import Pricing1 from '../views/Pages/Pricing1'
import BlankPage from '../views/Pages/BlankPage'
import FAQ from '../views/Pages/FAQ'
import Invoice from '../views/Pages/Invoice'
/* Apps Views */
import EmailListing from '../views/Apps/Email/EmailListing'
import EmailCompose from '../views/Apps/Email/EmailCompose'
import Calendar from '../views/Apps/Calendar/Calendar'
import ChatIndex from '../views/Apps/Chat/Index'
/* Icon Views */
import IconDripicons from '../views/Icons/IconDripicons'
import IconFontawesome5 from '../views/Icons/IconFontawesome5'
import IconLineAwesome from '../views/Icons/IconLineAwesome'
import IconRemixicon from '../views/Icons/IconRemixicon'
import IconUnicons from '../views/Icons/IconUnicons'
/* Tables Views */
import TablesBasic from '../views/Tables/TablesBasic'
import DataTable from '../views/Tables/DataTable'
import EditableTable from '../views/Tables/EditableTable'
import ApexCharts from '../views/Charts/ApexCharts'
import AmCharts from '../views/Charts/AmCharts'
import MorrisCharts from '../views/Charts/MorrisCharts'
import HighCharts from '../views/Charts/HighCharts'
import GoogleMaps from '../views/Maps/GoogleMaps'
import VectorMaps from '../views/Maps/VectorMaps'
/* Form View */
import FormLayout from '../views/Forms/FormLayout'
import FormValidates from '../views/Forms/FormValidates'
import FormSwitches from '../views/Forms/FormSwitches'
import FormRadios from '../views/Forms/FormRadios'
import FormCheckboxes from '../views/Forms/FormCheckboxes'
/* User View */
import Profile from '../views/User/Profile'
import ProfileEdit from '../views/User/ProfileEdit'
import AddUser from '../views/User/AddUser'
import UserList from '../views/User/UserList'
/* Todo */
import Callback from '../views/AuthPages/Default/Callback'
/* Plugins Views */
import DatepickerDemo from '../views/Plugins/DatepickerDemo'
import SelectDemo from '../views/Plugins/SelectDemo'
import DragDropDemo from '../views/Plugins/DragDropDemo'
import AppTreeView from '../views/Plugins/AppTreeView'

/* OpsFleet Views */
import PMS from '../views/PMS/PMS.vue'
import Portal from '../views/PMS/Portal.vue'
import InputVoyage from '../views/PMS/InputVoyage.vue'
import Operational from '../views/PMS/Operational.vue'
import OperationalFLoatingCrane from '../views/PMS/OperationalFloatingCrane.vue'
import OperationalInput from '../views/PMS/OperationalInput'
import OperationalInputFloatingCrane from '../views/PMS/OperationalInputFloatingCrane'
import MasterPort from '../views/MasterPages/MasterPort.vue'
import MasterProjectIdle from '../views/MasterPages/MasterProjectIdle.vue'
import MasterCertificateReasons from '../views/Crewing/MasterCertificateReasons.vue'
import MasterTypePower from '../views/MasterPages/MasterTypePower.vue'
import AddFleet from '../views/MasterPages/AddFleet.vue'
import UserRolePrivilege from '../views/Setting/UserRolePrivilege.vue'
import ModuleCategory from '../views/Setting/ModuleCategory.vue'
import Users from '../views/Setting/Users.vue'
import Logs from '../views/Setting/Logs.vue'
import AddUsers from '../views/Setting/AddUser.vue'
import ViewUser from '../views/Setting/ViewUser.vue'
import EditUser from '../views/Setting/UserEdit.vue'
import TemplateSetting from '../views/Setting/Template.vue'

import FleetInformation from '../views/PMS/FleetInformation.vue'
import MaintenanceFleet from '../views/PMS/Maintenance.vue'
import SCLAdd from '../views/PMS/SclAdd.vue'

// Me
import MyProfile from '../views/Me/MyProfile.vue'
import UpdateProfile from '../views/Me/UpdateProfile.vue'

// Certificate
import Certificate from '../views/Certificate/Certificate.vue'
import Dashboard1 from '../views/Dashboards/Dashboard1'
import Dashboard2 from '../views/Dashboards/Dashboard2'
import Dashboard3 from '../views/Dashboards/Dashboard3'
import Dashboard5 from '../views/Dashboards/Dashboard5'

/* Maintenance Views */
import MaintenanceSummary from '../views/Maintenance/MaintenanceSummary.vue'
import MaintenanceMasterEquipment from '../views/Maintenance/MaintenanceMasterEquipment.vue'
import MaintenanceSetupComponent from '../views/Maintenance/MaintenanceSetupComponent.vue'
import RunningHours from '../views/Maintenance/RunningHours.vue'
import SummaryROB from '../views/Maintenance/SummaryROB.vue'
import KanbanDragDrop from '../views/Maintenance/KanbanDragDrop.vue'
import BlankLayout from '../layouts/BlankLayout.vue'

// Docking View
import DockingMasterData from '../views/Docking/DockingMasterData.vue'
import DockingDashboard from '../views/Docking/DockingDashboard.vue'
import DockingSeaTrial from '../views/Docking/DockingSeaTrial.vue'
import DockingHistory from '../views/Docking/DockingHistory.vue'
import DockingSerahTerima from '../views/Docking/DockingSerahTerima.vue'
import Template from '../views/Docking/Template.vue'

/* Marine Insurance */
import MIPortal from '../views/MI/Portal.vue'
import ListInitialMI from '../views/MI/ListInitial.vue'
import MIClaimAnalysis from '../views/MI/ClaimAnalysis.vue'
import MIClaimAnalysisPreview from '../views/MI/ClaimAnalysisPreview.vue'
import MICostBreakdown from '../views/MI/CostBreakdown.vue'
import SurveyCostBreakdown from '../views/MI/survey-costbreakdown.vue'
import AddSurvey from '../views/MI/AddSurvey.vue'
import PriceOfFleet from '@src/views/MI/PriceOfFleet'
import MasterPureRisk from '@src/views/MI/master/MasterPureRisk'
import LossRatio from '@src/views/MI/LossRatio'
import ForecastCondition from '@src/views/MI/ForecastCondition'
import ManagementApproval from '@src/views/MI/ManagementApproval'
import ForecastConditionInput from '@src/views/MI/ForecastConditionInput'
import RootCauseAnalysis from '@src/views/MI/RootCauseAnalysis'
import MasterGoods from '@src/views/Goods/MasterGoods'
import GoodsInOut from '@src/views/Goods/GoodsInOut'
import GoodsStockMovement from '@src/views/Goods/GoodsStockMovement'
import StockOpname from '@src/views/Goods/StockOpname'
import AddStockOpname from '@src/views/Goods/AddStockOpname'

// CREWING
import AddCandidate from '@src/views/Crewing/AddCandidate'
import AddCrew from '@src/views/Crewing/AddCrew'
import InterviewReview from '@src/views/Crewing/InterviewReview'
import ListCrew from '@src/views/Crewing/ListCrew'
import ProfileUser from '@src/views/Crewing/ProfileUser'
import EditProfileUser from '@src/views/Crewing/EditProfileUser'
import Condite from '@src/views/Crewing/Condite'
import Conduct from '@src/views/Crewing/Conduct'

// MI
import FinalReportRootCauseAnalysis from '@src/views/MI/FinalReportRootCauseAnalysis'
import ManagementSummary from '@src/views/MI/ManagementSummary'
import AddFormRecommendation from '@src/views/MI/AddFormRecommendation'
import AddFormEvaluation from '@src/views/MI/AddFormEvaluation'
import FormMonitoring from '@src/views/MI/FormMonitoring'
import EnterpriseRiskManagement from '@src/views/MI/EnterpriseRiskManagement'
import SummaryManagement from '@src/views/MI/SummaryManagement'
import ForecastConditionView from '@src/views/MI/ForecastConditionView'
import PriceOfFleetForm from '@src/views/MI/PriceOfFleetForm'

// SR
import ServiceRepairDashboard from '@src/views/Sr/ServiceRepairDashboard.vue'
import ServiceRepairHistory from '@src/views/Sr/ServiceRepairHistory.vue'
import ServiceRepairMasterData from '@src/views/Sr/ServiceRepairMasterData.vue'

// BUSDEV
import Customer from '@/src/views/Busdev/Customer/Customer.vue'
import Vendor from '@/src/views/Busdev/Vendor/Vendor.vue'
import MasterDataIdle from '@src/views/Busdev/Master/MasterDataIdle.vue'
import MasterForms from '@src/views/Busdev/CollectionForm/MasterForms.vue'
import InspectionChecklist from '@src/views/Busdev/InspectionChecklist/InspectionChecklist.vue'
import BusdevDashboard from '@src/views/Busdev/Dashboard/BusdevDashboard.vue'
import MasterPFA from '@src/views/Busdev/MasterPFA/MasterPFA.vue'
import Spal from '@src/views/Busdev/Spal/Spal.vue'

// PIC MOVEMENT
import PicMovement from '../views/PICMovement/PicMovement.vue'

// PLATFORM MODULES
import PlatformModule from '../views/PlatformModule/PlatformModule.vue'

// TEMPLATES DOCKING
import TemplatesDockingDashboard from '@src/views/Templates/DockingDashboard'
import TemplatesDockingSeaTrial from '@src/views/Templates/DockingSeaTrial'
import TemplatesDockingSerahTerima from '@src/views/Templates/DockingSerahTerima'
import TemplatesDockingHistory from '@src/views/Templates/DockingHistory'

// TEMPLATES SERVICE REPAIR
import TemplatesServiceRepairDashboard from '@src/views/Templates/ServiceRepairDashboard'
import TemplatesServiceRepairHistory from '@src/views/Templates/ServiceRepairHistory'

// TEMPLATES MAINTENANCE
import TemplatesMaintenanceDashboard from '@src/views/Templates/MaintenanceDashboard'
import TemplatesMaintenanceHistory from '@src/views/Templates/MaintenanceHistory'

// TEMPLATES CREWING
import TemplatesCrewingCondite from '@src/views/Templates/CrewingCondite'
import TemplatesCrewingConduct from '@src/views/Templates/CrewingConduct'

// TEMPLATES PROCUREMENT
import TemplatesProcurementDashboard from '@src/views/Templates/ProcurementDashboard'
import TemplatesProcurementVendor from '@src/views/Templates/ProcurementVendor'
import TemplatesProcurementProducts from '@src/views/Templates/ProcurementProducts'
import TemplatesProcurementRequisition from '@src/views/Templates/ProcurementRequisition'
import TemplatesProcurementPurchaseRequest from '@src/views/Templates/ProcurementPurchaseRequest'
import TemplatesProcurementPurchaseOrder from '@src/views/Templates/ProcurementPurchaseOrder'
import TemplatesProcurementPurchaseReceipt from '@src/views/Templates/ProcurementPurchaseReceipt'
import TemplatesProcurementSalesOrder from '@src/views/Templates/ProcurementSalesOrder'
import TemplatesProcurementSalesConfirmation from '@src/views/Templates/ProcurementSalesConfirmation'
import TemplatesProcurementInventory from '@src/views/Templates/ProcurementInventory'
import TemplatesProcurementReportGeneralLedger from '@src/views/Templates/ProcurementReportGeneralLedger'
import TemplatesProcurementReportArusKas from '@src/views/Templates/ProcurementReportArusKas'
import TemplatesProcurementReportLabaRugi from '@src/views/Templates/ProcurementReportLabaRugi'
import ForecastFinalResultIndependent from '@src/views/MI/ForecastFinalResultIndependent'

// TEMPLATES BUSDEV
import TemplatesBusdevDashboard from '@src/views/Templates/BusdevDashboard'
import TemplatesBusdevBudget from '@src/views/Templates/BusdevBudget'
import TemplatesBusdevFleetPerformance from '@src/views/Templates/BusdevFleetPerformance'

Vue.use(VueRouter)

const authChildRoutes = (prop, mode = false) => [
  {
    path: '/',
    name: prop + '.sign-in1',
    meta: { dark: mode, auth: true },
    component: SignIn1,
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    meta: { dark: mode, auth: true },
    component: SignUp1,
  },
  {
    path: 'reset-password',
    name: prop + '.reset-password',
    meta: { dark: mode, auth: true },
    component: RecoverPassword1,
  },
  {
    path: 'new-password',
    name: prop + '.new-password',
    meta: { dark: mode, auth: true },
    component: NewPassword,
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    meta: { dark: mode, auth: true },
    component: LockScreen1,
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    meta: { dark: mode, auth: true },
    component: ConfirmMail1,
  },
]

const authChildRoutes2 = (prop, mode = false) => [
  {
    path: 'login',
    name: prop + '.sign-in2',
    meta: { dark: mode, auth: true },
    component: SignIn2,
  },
]

const childRoutes = (prop, mode) => [
  {
    path: 'home-1',
    name: prop + '.home-1',
    meta: { dark: mode, auth: true, name: 'Home 1' },
    component: Dashboard1,
  },
  {
    path: 'home-2',
    name: prop + '.home-2',
    meta: { dark: mode, auth: true, name: 'Home 2' },
    component: Dashboard2,
  },
  {
    path: 'home-3',
    name: prop + '.home-3',
    meta: { dark: mode, auth: true, name: 'Home 3' },
    component: Dashboard3,
  },
  {
    path: 'home-5',
    name: prop + '.home-5',
    meta: { dark: mode, auth: true, name: 'Home 5' },
    component: Dashboard5,
  },
  {
    path: 'home-4',
    name: prop + '.home-4',
    meta: { dark: mode, auth: true, name: 'Home 4' },
    component: Dashboard4,
  },
  {
    path: 'home',
    name: prop + '.home',
    meta: { dark: mode, auth: true, name: 'Dashboard' },
    component: FleetDashboard,
  },
  {
    path: 'pms',
    name: prop + '.pms',
    meta: { dark: mode, auth: true, name: 'PMS' },
    component: PMS,
  },
  {
    path: 'operational',
    name: prop + '.operational',
    meta: { dark: mode, auth: true, name: 'Operational' },
    component: Operational,
  },
  {
    path: 'operational-floating-crane',
    name: prop + '.operational-floating-crane',
    meta: { dark: mode, auth: true, name: 'Operational Floating Crane' },
    component: OperationalFLoatingCrane,
  },
  {
    path: 'operational-input',
    name: prop + '.operational-input',
    meta: { dark: mode, auth: true, name: 'Operational Input' },
    component: OperationalInput,
  },
  {
    path: 'operational-input-fc',
    name: prop + '.operational-input-fc',
    meta: { dark: mode, auth: true, name: 'Operational Input Floating Crane' },
    component: OperationalInputFloatingCrane,
  },
  {
    path: 'fleet-information',
    name: prop + '.fleet-information',
    meta: { dark: mode, auth: true, name: 'FleetInformation' },
    component: FleetInformation,
  },
  {
    path: 'portal',
    name: prop + '.portal',
    meta: { dark: mode, auth: true, name: 'Portal' },
    component: Portal,
  },
  {
    path: 'input-voyage',
    name: prop + '.input-voyage',
    meta: { dark: mode, auth: true, name: 'InputVoyage' },
    component: InputVoyage,
  },
  {
    path: 'master-port',
    name: prop + '.master-port',
    meta: { dark: mode, auth: true, name: 'MasterPort' },
    component: MasterPort,
  },
  {
    path: 'master-project-idle',
    name: prop + '.master-project-idle',
    meta: { dark: mode, auth: true, name: 'MasterProjectIdle' },
    component: MasterProjectIdle,
  },
  {
    path: 'add-fleet',
    name: prop + '.add-fleet',
    meta: { dark: mode, auth: true, name: 'AddFleet' },
    component: AddFleet,
  },
  {
    path: 'master-type-power',
    name: prop + '.master-type-power',
    meta: { dark: mode, auth: true, name: 'MasterTypePower' },
    component: MasterTypePower,
  },
  {
    path: 'pic-movement',
    name: prop + '.pic-movement',
    meta: { dark: mode, auth: true, name: 'PicMovement' },
    component: PicMovement,
  },
  {
    path: 'platform-module',
    name: prop + '.platform-module',
    meta: { dark: mode, auth: true, name: 'PlatformModule' },
    component: PlatformModule,
  },

  // Certificate
  {
    path: 'certificate',
    name: prop + '.certificate',
    meta: { dark: mode, auth: true, name: 'Certificate' },
    component: Certificate,
  },
  // Maintenance
  {
    path: 'maintenance-fleet',
    name: prop + '.maintenance-fleet',
    meta: { dark: mode, auth: true, name: 'Maintenance Fleet' },
    component: MaintenanceFleet,
  },
]

const sclChildRoutes = (prop, mode) => [
  {
    path: 'scl-add',
    name: prop + '.scl-add',
    meta: { dark: mode, auth: true, name: 'SCL Add' },
    component: SCLAdd,
  },
]

const settingChildRoutes = (prop, mode) => [
  {
    path: 'user-role-privilege',
    name: prop + '.settingUser',
    meta: { dark: mode, auth: true, name: 'settingUser' },
    component: UserRolePrivilege,
  },
  {
    path: 'module-category',
    name: prop + '.moduleCategory',
    meta: { dark: mode, auth: true, name: 'moduleCategory' },
    component: ModuleCategory,
  },
  {
    path: 'users',
    name: prop + '.users',
    meta: { dark: mode, auth: true, name: 'users' },
    component: Users,
  },
  {
    path: 'logs',
    name: prop + '.logs',
    meta: { dark: mode, auth: true, name: 'logs' },
    component: Logs,
  },
  {
    path: 'add-new-user',
    name: prop + '.addNewUser',
    meta: { dark: mode, auth: true, name: 'addNewUser' },
    component: AddUsers,
  },
  {
    path: 'view-user/:id',
    name: prop + '.viewUser',
    meta: { dark: mode, auth: true, name: 'viewUser' },
    component: ViewUser,
  },
  {
    path: '/edit-user/:id',
    name: prop + '.EditUser',
    meta: { dark: mode, auth: true, name: 'EditUser' },
    component: EditUser,
  }, 
  {
    path: 'template',
    name: prop + '.Template',
    meta: { dark: mode, auth: true, name: 'TemplateSetting' },
    component: TemplateSetting,
  },
]

const meChildRoutes = (prop, mode) => [
  {
    path: 'my-profile',
    name: prop + '.myProfile',
    meta: { dark: mode, auth: true, name: 'myProfile' },
    component: MyProfile,
  },
  {
    path: 'update-profile',
    name: prop + '.updateProfile',
    meta: { dark: mode, auth: true, name: 'updateProfile' },
    component: UpdateProfile,
  },
]

const miChildRoutes = (prop, mode) => [
  {
    path: 'portal',
    name: prop + '.portal',
    meta: { dark: mode, auth: true, name: 'portal' },
    component: MIPortal,
  },
  {
    path: 'list-initial',
    name: prop + '.listInitial',
    meta: { dark: mode, auth: true, name: 'listInitial' },
    component: ListInitialMI,
  },
  {
    path: 'master-survey-costbreakdown',
    name: prop + '.master-survey-costbreakdown',
    meta: { dark: mode, auth: true, name: 'master-survey-costbreakdown' },
    component: SurveyCostBreakdown,
  },
  {
    path: 'add-survey',
    name: prop + '.add-survey',
    meta: { dark: mode, auth: true, name: 'add-survey' },
    component: AddSurvey,
  },
  {
    path: 'price-fleet',
    name: prop + '.price-fleet',
    meta: { dark: mode, auth: true, name: 'price-fleet' },
    component: PriceOfFleet,
  },
  {
    path: 'price-fleet-form',
    name: prop + '.price-fleet-form',
    meta: { dark: mode, auth: true, name: 'price-fleet-form' },
    component: PriceOfFleetForm,
  },
  {
    path: 'master-pure-risk',
    name: prop + '.master-pure-risk',
    meta: { dark: mode, auth: true, name: 'master-pure-risk' },
    component: MasterPureRisk,
  },
  {
    path: 'claim-analysis', // Claim Calculation
    name: prop + '.claim-analysis',
    meta: { dark: mode, auth: true, name: 'claim-analysis' },
    component: MIClaimAnalysis,
  },
  {
    path: 'cost-breakdown', // Cost Breakdown
    name: prop + '.cost-breakdown',
    meta: { dark: mode, auth: true, name: 'cost-breakdown' },
    component: MICostBreakdown,
  },
  {
    path: 'forecast-condition', // Forecast Condition
    name: prop + '.forecast-condition',
    meta: { dark: mode, auth: true, name: 'forecast-condition' },
    component: ForecastCondition,
  },
  {
    path: 'forecast-condition-input', // Forecast Condition Input
    name: prop + '.forecast-condition-input',
    meta: { dark: mode, auth: true, name: 'forecast-condition-input' },
    component: ForecastConditionInput,
  },
  {
    path: 'forecast-condition-view', // Forecast Condition Input
    name: prop + '.forecast-condition-view',
    meta: { dark: mode, auth: true, name: 'forecast-condition-view' },
    component: ForecastConditionView,
  },
  {
    path: 'forecast-final-independent', // forecast-final-independent
    name: prop + '.forecast-final-independent',
    meta: { dark: mode, auth: true, name: 'forecast-final-independent' },
    component: ForecastFinalResultIndependent,
  },
  {
    path: 'loss-ratio-input', // Lost Ratio
    name: prop + '.loss-ratio-input',
    meta: { dark: mode, auth: true, name: 'loss-ratio-input' },
    component: LossRatio,
  },
  {
    path: 'management-approval', // Management Approval
    name: prop + '.management-approval',
    meta: { dark: mode, auth: true, name: 'management-approval' },
    component: ManagementApproval,
  },
  {
    path: 'root-cause-analysis/:id?', // Root Cause Analysis
    name: prop + '.root-cause-analysis',
    meta: { dark: mode, auth: true, name: 'root-cause-analysis' },
    component: RootCauseAnalysis,
  },
  {
    path: 'final-report-root-cause-analysis', // Root Cause Analysis
    name: prop + '.final-report-root-cause-analysis',
    meta: { dark: mode, auth: true, name: 'final-report-root-cause-analysis' },
    component: FinalReportRootCauseAnalysis,
  },
  {
    path: 'management-summary', // Management Summary
    name: prop + '.management-summary',
    meta: { dark: mode, auth: true, name: 'management-summary' },
    component: ManagementSummary,
  },
  {
    path: 'add-form-recommendation/:id?', // Fprm Recommendation
    name: prop + '.add-form-recommendation',
    meta: { dark: mode, auth: true, name: 'add-form-recommendation' },
    component: AddFormRecommendation,
  },
  {
    path: 'add-form-evaluation', // Form Evaluation
    name: prop + '.add-form-evaluation',
    meta: { dark: mode, auth: true, name: 'add-form-evaluation' },
    component: AddFormEvaluation,
  },
  {
    path: 'monitoring-form', // Form Monitoring
    name: prop + '.add-form-monitoring',
    meta: { dark: mode, auth: true, name: 'add-form-monitoring' },
    component: FormMonitoring,
  },
  {
    path: 'enterprise-risk-management', // Enterprise Risk Management
    name: prop + '.enterprise-risk-management',
    meta: { dark: mode, auth: true, name: 'enterprise-risk-management' },
    component: EnterpriseRiskManagement,
  },
  {
    path: 'summary-management', // Summary Management
    name: prop + '.summary-management',
    meta: { dark: mode, auth: true, name: 'summary-management' },
    component: SummaryManagement,
  },
]

const goodsChildRoutes = (prop, mode) => [
  {
    path: 'master-goods',
    name: prop + '.master-goods',
    meta: { dark: mode, auth: true, name: 'portal' },
    component: MasterGoods,
  },
  {
    path: 'goods-in-out',
    name: prop + '.goods-in-out',
    meta: { dark: mode, auth: true, name: 'goods-in-out' },
    component: GoodsInOut,
  },
  {
    path: 'goods-stock-movement',
    name: prop + '.goods-stock-movement',
    meta: { dark: mode, auth: true, name: 'goods-stock-movement' },
    component: GoodsStockMovement,
  },
  {
    path: 'stock-opname',
    name: prop + '.stock-opname',
    meta: { dark: mode, auth: true, name: 'stock-opname' },
    component: StockOpname,
  },
  {
    path: 'add-stock-opname',
    name: prop + '.add-stock-opname',
    meta: { dark: mode, auth: true, name: 'add-stock-opname' },
    component: AddStockOpname,
  },
]

const maintenanceChildRoutes = (prop, mode) => [
  {
    path: 'maintenance-summary',
    name: prop + '.maintenanceSummary',
    meta: { dark: mode, auth: true, name: 'maintenanceSummary' },
    component: MaintenanceSummary,
  },
  {
    path: 'maintenance-equipment',
    name: prop + '.maintenanceEquipment',
    meta: { dark: mode, auth: true, name: 'maintenanceEquipment' },
    component: MaintenanceMasterEquipment,
  },
  {
    path: 'maintenance-setup-component',
    name: prop + '.maintenanceSetupComponent',
    meta: { dark: mode, auth: true, name: 'maintenanceSetupComponent' },
    component: MaintenanceSetupComponent,
  },
  {
    path: 'running-hours',
    name: prop + '.runningHours',
    meta: { dark: mode, auth: true, name: 'runningHours' },
    component: RunningHours,
  },
  {
    path: 'summary-rob',
    name: prop + '.summaryROB',
    meta: { dark: mode, auth: true, name: 'summaryROB' },
    component: SummaryROB,
  },
  {
    path: 'sample-dragdrop',
    name: prop + '.sampleDragDrop',
    meta: { dark: mode, auth: true, name: 'KanbanDragDrop' },
    component: KanbanDragDrop,
  },
]

const crewingChildRoutes = (prop, mode) => [
  {
    path: 'interview-review/:id',
    name: prop + '.interview-review',
    meta: { dark: mode, auth: true, name: 'interviewReview' },
    component: InterviewReview,
  },
  {
    path: 'list-crew',
    name: prop + '.list-crew',
    meta: { dark: mode, auth: true, name: 'listCrew' },
    component: ListCrew,
  },
  {
    path: 'add-crew',
    name: prop + '.add-crew',
    meta: { dark: mode, auth: true, name: 'AddCrew' },
    component: AddCrew,
  },
  {
    path: 'add-candidate',
    name: prop + '.add-candidate',
    meta: { dark: mode, auth: true, name: 'AddCandidate' },
    component: AddCandidate,
  },
  {
    path: 'profile',
    name: prop + '.profile-user',
    meta: { dark: mode, auth: true, name: 'ProfileUser' },
    component: ProfileUser,
  },
  {
    path: 'profile-edit',
    name: prop + '.profile-edit',
    meta: { dark: mode, auth: true, name: 'EditProfileUser' },
    component: EditProfileUser,
  },

  {
    path: 'condite/:id',
    name: prop + '.condite',
    meta: { dark: mode, auth: true, name: 'condite' },
    component: Condite,
  },
  {
    path: 'conduct/:id',
    name: prop + '.conduct',
    meta: { dark: mode, auth: true, name: 'conduct' },
    component: Conduct,
  },
  {
    path: 'master-certificate-reasons',
    name: prop + '.master-certificate-reasons',
    meta: { dark: mode, auth: true, name: 'MasterCertificateReasons' },
    component: MasterCertificateReasons,
  },
]

const srChildRoutes = (prop, mode) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { dark: mode, auth: true, name: 'ServiceRepairDashboard' },
    component: ServiceRepairDashboard,
  },
  {
    path: 'history',
    name: prop + '.history',
    meta: { dark: mode, auth: true, name: 'ServiceRepairHistory' },
    component: ServiceRepairHistory,
  },
  {
    path: 'master-data',
    name: prop + '.master-data',
    meta: { dark: mode, auth: true, name: 'ServiceRepairMasterData' },
    component: ServiceRepairMasterData,
  },
]

/*const busdevChildRoutes = (prop, mode) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { dark: mode, auth: true, name: 'BusdevDashboard' },
    component: BusdevDashboard,
  },
  {
    path: 'customer',
    name: prop + '.customer',
    meta: { dark: mode, auth: true, name: 'Customer' },
    component: Customer,
  },
  {
    path: 'vendor',
    name: prop + '.vendor',
    meta: { dark: mode, auth: true, name: 'Vendor' },
    component: Vendor,
  }, 
  {
    path: 'master-data',
    name: prop + '.master-data',
    meta: { dark: mode, auth: true, name: 'MasterDataIdle' },
    component: MasterDataIdle,
  },  
  {
    path: 'master-forms',
    name: prop + '.master-forms',
    meta: { dark: mode, auth: true, name: 'MasterForms' },
    component: MasterForms,
  },
  {
    path: 'inspection-checklist',
    name: prop + '.inspection-checklist',
    meta: { dark: mode, auth: true, name: 'InspectionChecklist' },
    component: InspectionChecklist,
  }, 
  {
    path: 'master-pfa',
    name: prop + '.master-pfa',
    meta: { dark: mode, auth: true, name: 'MasterPFA' },
    component: MasterPFA,
  }, 
  {
    path: 'spal',
    name: prop + '.spal',
    meta: { dark: mode, auth: true, name: 'Spal' },
    component: Spal,
  },
]*/

const dockingChildRoutes = (prop, mode) => [
  {
    path: 'dashboard',
    name: prop + '.dashboard',
    meta: { dark: mode, auth: true, name: 'Docking Dashboard' },
    component: DockingDashboard,
  },
  {
    path: 'master-data',
    name: prop + '.master-data',
    meta: { dark: mode, auth: true, name: 'Docking - Master Data' },
    component: DockingMasterData,
  },
  {
    path: 'sea-trial',
    name: prop + '.sea-trial',
    meta: { dark: mode, auth: true, name: 'Docking - Sea Trial' },
    component: DockingSeaTrial,
  },
  {
    path: 'docking-history',
    name: prop + '.docking-history',
    meta: { dark: mode, auth: true, name: 'Docking History' },
    component: DockingHistory,
  },
  {
    path: 'serah-terima',
    name: prop + '.serah-terima',
    meta: { dark: mode, auth: true, name: 'Docking Serah Terima' },
    component: DockingSerahTerima,
  },
  {
    path: 'template',
    name: prop + '.template',
    meta: { dark: mode, auth: true, name: 'Template Docking' },
    component: Template,
  },
]

const coreChildRoute = (prop, mode) => [
  {
    path: 'ui-colors',
    name: prop + '.color',
    meta: { dark: mode, auth: true, name: 'Colors' },
    component: UiColors,
  },
  {
    path: 'ui-typography',
    name: prop + '.typography',
    meta: { dark: mode, auth: true, name: 'Typography' },
    component: UiTypography,
  },
  {
    path: 'ui-alerts',
    name: prop + '.alert',
    meta: { dark: mode, auth: true, name: 'Alert' },
    component: UiAlerts,
  },
  {
    path: 'ui-buttons',
    name: prop + '.button',
    meta: { dark: mode, auth: true, name: 'Button' },
    component: UiButtons,
  },
  {
    path: 'ui-breadcrumb',
    name: prop + '.breadcrumb',
    meta: { dark: mode, auth: true, name: 'Breadcrumb' },
    component: UiBreadcrumb,
  },
  {
    path: 'ui-badges',
    name: prop + '.badges',
    meta: { dark: mode, auth: true, name: 'Badges' },
    component: UiBadges,
  },
  {
    path: 'ui-cards',
    name: prop + '.cards',
    meta: { dark: mode, auth: true, name: 'Card' },
    component: UiCards,
  },
  {
    path: 'ui-carousel',
    name: prop + '.carousel',
    meta: { dark: mode, auth: true, name: 'Carousel' },
    component: UiCarousel,
  },
  {
    path: 'ui-grid',
    name: prop + '.grid',
    meta: { dark: mode, auth: true, name: 'Grid' },
    component: UiGrid,
  },
  {
    path: 'ui-embed-video',
    name: prop + '.embed-video',
    meta: { dark: mode, auth: true, name: 'Embed Video' },
    component: UiEmbedVideo,
  },
  {
    path: 'ui-modal',
    name: prop + '.modal',
    meta: { dark: mode, auth: true, name: 'Model' },
    component: UiModal,
  },
  {
    path: 'ui-listgroup',
    name: prop + '.listgroup',
    meta: { dark: mode, auth: true, name: 'List Group' },
    component: UiListGroup,
  },
  {
    path: 'ui-images',
    name: prop + '.images',
    meta: { dark: mode, auth: true, name: 'Image' },
    component: UiImages,
  },
  {
    path: 'ui-media-object',
    name: prop + '.media-object',
    meta: { dark: mode, auth: true, name: 'Media Object' },
    component: UiMediaObject,
  },
  {
    path: 'ui-pagination',
    name: prop + '.pagination',
    meta: { dark: mode, auth: true, name: 'Paginations' },
    component: UiPagination,
  },
  {
    path: 'ui-progressbars',
    name: prop + '.progressbars',
    meta: { dark: mode, auth: true, name: 'Progressbar' },
    component: UiProgressBars,
  },
  {
    path: 'ui-tooltips',
    name: prop + '.tooltips',
    meta: { dark: mode, auth: true, name: 'Tooltip' },
    component: UiTooltips,
  },
  {
    path: 'ui-popovers',
    name: prop + '.popovers',
    meta: { dark: mode, auth: true, name: 'Popover' },
    component: UiPopovers,
  },
  {
    path: 'ui-notifications',
    name: prop + '.notifications',
    meta: { dark: mode, auth: true, name: 'Notification' },
    component: UiNotifications,
  },
  {
    path: 'ui-tabs',
    name: prop + '.tabs',
    meta: { dark: mode, auth: true, name: 'Tabs' },
    component: UiTabs,
  },
]
const formChildRoute = (prop, mode = false) => [
  {
    path: 'form-layout',
    name: prop + '.layout',
    meta: { dark: mode, auth: true, name: 'Layout' },
    component: FormLayout,
  },
  {
    path: 'form-validate',
    name: prop + '.validate',
    meta: { dark: mode, auth: true, name: 'Validate' },
    component: FormValidates,
  },
  {
    path: 'form-switches',
    name: prop + '.switch',
    meta: { dark: mode, auth: true, name: 'Switch' },
    component: FormSwitches,
  },
  {
    path: 'form-radios',
    name: prop + '.radio',
    meta: { dark: mode, auth: true, name: 'Radio' },
    component: FormRadios,
  },
  {
    path: 'form-checkboxes',
    name: prop + '.checkbox',
    meta: { dark: mode, auth: true, name: 'Checkbox' },
    component: FormCheckboxes,
  },
]

const tableChildRoute = (prop, mode = false) => [
  {
    path: 'tables-basic',
    name: prop + '.basic',
    meta: { dark: mode, auth: true, name: 'Basic' },
    component: TablesBasic,
  },
  {
    path: 'data-table',
    name: prop + '.dataTable',
    meta: { dark: mode, auth: true, name: 'Datatable' },
    component: DataTable,
  },
  {
    path: 'editable',
    name: prop + '.editable',
    meta: { dark: mode, auth: true, name: 'Editable' },
    component: EditableTable,
  },
]

const iconChildRoute = (prop, mode = false) => [
  {
    path: 'dripicons',
    name: prop + '.dripicons',
    meta: { dark: mode, auth: true, name: 'Dripicons' },
    component: IconDripicons,
  },
  {
    path: 'fontawesome-5',
    name: prop + '.fontawesome-5',
    meta: { dark: mode, auth: true, name: 'Font Awsome' },
    component: IconFontawesome5,
  },
  {
    path: 'lineawesome',
    name: prop + '.lineawesome',
    meta: { dark: mode, auth: true, name: 'Line Awsome' },
    component: IconLineAwesome,
  },
  {
    path: 'remixicon',
    name: prop + '.remixicon',
    meta: { dark: mode, auth: true, name: 'Remixicon' },
    component: IconRemixicon,
  },
  {
    path: 'unicons',
    name: prop + '.unicons',
    meta: { dark: mode, auth: true, name: 'Unicon' },
    component: IconUnicons,
  },
]

const chartChildRoutes = (prop, mode = false) => [
  {
    path: 'apex-charts',
    name: prop + '.apex',
    meta: { dark: mode, auth: true, name: 'Apex Chat' },
    component: ApexCharts,
  },
  {
    path: 'am-charts',
    name: prop + '.am',
    meta: { dark: mode, auth: true, name: 'Am Chart' },
    component: AmCharts,
  },
  {
    path: 'high-charts',
    name: prop + '.high',
    meta: { dark: mode, auth: true, name: 'High Chart' },
    component: HighCharts,
  },
  {
    path: 'morris-charts',
    name: prop + '.morris',
    meta: { dark: mode, auth: true, name: 'Mirris Chart' },
    component: MorrisCharts,
  },
]

const appChildRoute = (prop, mode = false) => [
  {
    path: 'calendar',
    name: prop + '.calendar',
    meta: { dark: mode, auth: true, name: 'Calendar' },
    component: Calendar,
  },
  {
    path: 'chat',
    name: prop + '.chat',
    meta: { dark: mode, auth: true, name: 'Chat' },
    component: ChatIndex,
  },
  {
    path: 'email',
    name: prop + '.email',
    meta: { dark: mode, auth: true, name: 'Email' },
    component: EmailListing,
  },
  {
    path: 'email-compose',
    name: prop + '.email.compose',
    meta: { dark: mode, auth: true, name: 'New Email' },
    component: EmailCompose,
  },
]

const mapChildRoute = (prop, mode = false) => [
  {
    path: 'google-maps',
    name: prop + '.google',
    meta: { dark: mode, auth: true, name: 'Google map' },
    component: GoogleMaps,
  },
  {
    path: 'vector-maps',
    name: prop + '.vector',
    meta: { dark: mode, auth: true, name: 'Vector Detail' },
    component: VectorMaps,
  },
]

const defaultlayout = (prop, mode = false) => [
  {
    path: 'timeline',
    name: prop + '.timeline',
    meta: { dark: mode, auth: true, name: 'Timeline' },
    component: TimeLine,
  },
  {
    path: 'invoice',
    name: prop + '.invoice',
    meta: { dark: mode, auth: true, name: 'Invoice' },
    component: Invoice,
  },
  {
    path: 'blank-page',
    name: prop + '.blank-page',
    meta: { dark: mode, auth: true, name: 'Blank Page' },
    component: BlankPage,
  },
  {
    path: 'pricing',
    name: prop + '.pricing',
    meta: { dark: mode, auth: true, name: 'Pricing' },
    component: Pricing,
  },
  {
    path: 'pricing-1',
    name: prop + '.pricing1',
    meta: { dark: mode, auth: true, name: 'Pricing 1' },
    component: Pricing1,
  },
  {
    path: 'faq',
    name: prop + '.faq',
    meta: { dark: mode, auth: true, name: 'Faq' },
    component: FAQ,
  },
]

const pagesChildRoutes = (prop, mode = false) => [
  {
    path: 'error/:code',
    name: prop + '.error',
    meta: { dark: mode, auth: true },
    component: ErrorPage,
  },
  {
    path: 'coming-soon',
    name: prop + '.coming-soon',
    meta: { dark: mode, auth: true },
    component: ComingSoon,
  },
  {
    path: 'maintenance',
    name: prop + '.maintenance',
    meta: { dark: mode, auth: true },
    component: Maintenance,
  },
]
const userChildRoute = (prop, mode = false) => [
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { dark: mode, auth: true, name: 'Profile' },
    component: Profile,
  },
  {
    path: 'profile-edit',
    name: prop + '.edit',
    meta: { dark: mode, auth: true, name: 'Edit Profile' },
    component: ProfileEdit,
  },
  {
    path: 'add-user',
    name: prop + '.add',
    meta: { dark: mode, auth: true, name: 'Add Profile' },
    component: AddUser,
  },
  {
    path: 'user-list',
    name: prop + '.list',
    meta: { dark: mode, auth: true, name: 'User List' },
    component: UserList,
  },
]

const pluginsChildRoute = (prop, mode = false) => [
  {
    path: 'datepicker',
    name: prop + '.datepicker',
    meta: { dark: mode, auth: true, name: 'Datepicker' },
    component: DatepickerDemo,
  },
  {
    path: 'select',
    name: prop + '.select',
    meta: { dark: mode, auth: true, name: 'Select' },
    component: SelectDemo,
  },
  {
    path: 'draggable',
    name: prop + '.draggable',
    meta: { dark: mode, auth: true, name: 'Draggable' },
    component: DragDropDemo,
  },
  {
    path: 'treeview',
    name: prop + '.treeview',
    meta: { dark: mode, auth: true, name: 'Tree View' },
    component: AppTreeView,
  },
]

const templatesChildRoutes = (prop, mode) => [
  // DOCKING
  {
    path: 'docking-dashboard',
    name: prop + '.docking-dashboard',
    meta: { dark: mode, auth: true, name: 'Docking Dashboard' },
    component: TemplatesDockingDashboard,
  },
  {
    path: 'docking-sea-trial',
    name: prop + '.docking-sea-trial',
    meta: { dark: mode, auth: true, name: 'Docking - Sea Trial' },
    component: TemplatesDockingSeaTrial,
  },
  {
    path: 'docking-serah-terima',
    name: prop + '.docking-serah-terima',
    meta: { dark: mode, auth: true, name: 'Docking - Serah Terima' },
    component: TemplatesDockingSerahTerima,
  },
  {
    path: 'docking-history',
    name: prop + '.docking-history',
    meta: { dark: mode, auth: true, name: 'Docking History' },
    component: TemplatesDockingHistory,
  },


  // SERVICE REPAIR
  {
    path: 'service-repair-dashboard',
    name: prop + '.service-repair-dashboard',
    meta: { dark: mode, auth: true, name: 'Service Repair Dashboard' },
    component: TemplatesServiceRepairDashboard,
  },
  {
    path: 'service-repair-history',
    name: prop + '.service-repair-history',
    meta: { dark: mode, auth: true, name: 'Service Repair History' },
    component: TemplatesServiceRepairHistory,
  },

  // MAINTENANCE
  {
    path: 'maintenance-dashboard',
    name: prop + '.maintenance-dashboard',
    meta: { dark: mode, auth: true, name: 'Maintenance Dashboard' },
    component: TemplatesMaintenanceDashboard,
  },
  {
    path: 'maintenance-history',
    name: prop + '.maintenance-history',
    meta: { dark: mode, auth: true, name: 'Maintenance History' },
    component: TemplatesMaintenanceHistory,
  },

  // CREWING
  {
    path: 'crewing-condite',
    name: prop + '.crewing-condite',
    meta: { dark: mode, auth: true, name: 'Crewing Condite' },
    component: TemplatesCrewingCondite,
  },
  {
    path: 'crewing-conduct',
    name: prop + '.crewing-conduct',
    meta: { dark: mode, auth: true, name: 'Crewing Conduct' },
    component: TemplatesCrewingConduct,
  },

  // PROCUREMENT
  {
    path: 'procurement-dashboard',
    name: prop + '.procurement-dashboard',
    meta: { dark: mode, auth: true, name: 'Procurement New Dashboard' },
    component: TemplatesProcurementDashboard,
  },
  {
    path: 'procurement-vendor',
    name: prop + '.procurement-vendor',
    meta: { dark: mode, auth: true, name: 'Procurement New Vendor' },
    component: TemplatesProcurementVendor,
  },
  {
    path: 'procurement-products',
    name: prop + '.procurement-products',
    meta: { dark: mode, auth: true, name: 'Procurement Products' },
    component: TemplatesProcurementProducts,
  },
  {
    path: 'procurement-requisition',
    name: prop + '.procurement-requisition',
    meta: { dark: mode, auth: true, name: 'Procurement Requisition' },
    component: TemplatesProcurementRequisition,
  },
  {
    path: 'procurement-purchase-request',
    name: prop + '.procurement-purchase-request',
    meta: { dark: mode, auth: true, name: 'Procurement Purchase Request' },
    component: TemplatesProcurementPurchaseRequest,
  },
  {
    path: 'procurement-purchase-order',
    name: prop + '.procurement-purchase-order',
    meta: { dark: mode, auth: true, name: 'Procurement Purchase Order' },
    component: TemplatesProcurementPurchaseOrder,
  },
  {
    path: 'procurement-purchase-receipt',
    name: prop + '.procurement-purchase-receipt',
    meta: { dark: mode, auth: true, name: 'Procurement Purchase Receipt' },
    component: TemplatesProcurementPurchaseReceipt,
  },
  {
    path: 'procurement-sales-order',
    name: prop + '.procurement-sales-order',
    meta: { dark: mode, auth: true, name: 'Procurement Sales Order' },
    component: TemplatesProcurementSalesOrder,
  },
  {
    path: 'procurement-sales-confirmation',
    name: prop + '.procurement-sales-confirmation',
    meta: { dark: mode, auth: true, name: 'Procurement Sales Confirmation' },
    component: TemplatesProcurementSalesConfirmation,
  },
  {
    path: 'procurement-inventory',
    name: prop + '.procurement-inventory',
    meta: { dark: mode, auth: true, name: 'Procurement Inventory' },
    component: TemplatesProcurementInventory,
  },
  {
    path: 'procurement-report-gl',
    name: prop + '.procurement-report-gl',
    meta: { dark: mode, auth: true, name: 'Procurement Report GL' },
    component: TemplatesProcurementReportGeneralLedger,
  },
  {
    path: 'procurement-report-arus-kas',
    name: prop + '.procurement-report-arus-kas',
    meta: { dark: mode, auth: true, name: 'Procurement Report Arus Kas' },
    component: TemplatesProcurementReportArusKas,
  },
  {
    path: 'procurement-report-laba-rugi',
    name: prop + '.procurement-report-laba-rugi',
    meta: { dark: mode, auth: true, name: 'Procurement Report Laba Rugi' },
    component: TemplatesProcurementReportLabaRugi,
  },
  // BUSDEV
  {
    path: 'busdev-dashboard',
    name: prop + '.busdev-dashboard',
    meta: { dark: mode, auth: true, name: 'Dashboard Business Development' },
    component: TemplatesBusdevDashboard,
  },
  {
    path: 'busdev-budget',
    name: prop + '.busdev-budget',
    meta: { dark: mode, auth: true, name: 'Budget Business Development' },
    component: TemplatesBusdevBudget,
  },
  {
    path: 'busdev-fleet-performance',
    name: prop + '.busdev-fleet-performance',
    meta: { dark: mode, auth: true, name: 'Busdev Fleet Performance' },
    component: TemplatesBusdevFleetPerformance,
  },
]

const routes = [
  {
    path: '/ops',
    name: 'dashboard',
    component: Layout1,
    meta: { auth: true },
    children: childRoutes('dashboard'),
  },
  {
    path: '/scl',
    name: 'scl',
    component: BlankLayout,
    meta: { auth: true },
    children: sclChildRoutes('scl'),
  },
  {
    path: '/mi/claim-analysis/preview/:id', // Claim Calculation
    name: 'claim-analysis-preview',
    meta: { auth: true },
    component: MIClaimAnalysisPreview,
  },
  {
    path: '/setting',
    name: 'setting',
    component: Layout1,
    meta: { auth: true },
    children: settingChildRoutes('setting'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Layout1,
    meta: { auth: true },
    children: maintenanceChildRoutes('Maintenance'),
  },
  {
    path: '/docking',
    name: 'docking',
    component: Layout1,
    meta: { auth: true },
    children: dockingChildRoutes('docking'),
  },
  {
    path: '/me',
    name: 'me',
    component: Layout1,
    meta: { auth: true },
    children: meChildRoutes('me'),
  },
  {
    path: '/mi',
    name: 'mi',
    component: Layout1,
    meta: { auth: true },
    children: miChildRoutes('mi'),
  },
  {
    path: '/goods',
    name: 'goods',
    component: Layout1,
    meta: { auth: true },
    children: goodsChildRoutes('goods'),
  },
  {
    path: '/crewing',
    name: 'crewing',
    component: Layout1,
    meta: { auth: true },
    children: crewingChildRoutes('crewing'),
  },
  {
    path: '/sr',
    name: 'sr',
    component: Layout1,
    meta: { auth: true },
    children: srChildRoutes('sr'),
  },
  /*{
    path: '/busdev',
    name: 'busdev',
    component: Layout1,
    meta: { auth: true },
    children: busdevChildRoutes('busdev'),
  },*/
  {
    path: '/core',
    name: 'core',
    component: Layout1,
    meta: { auth: true },
    children: coreChildRoute('core'),
  },
  {
    path: '/table',
    name: 'table',
    component: Layout1,
    meta: { auth: true },
    children: tableChildRoute('table'),
  },
  {
    path: '/chart',
    name: 'chart',
    component: Layout1,
    meta: { auth: true },
    children: chartChildRoutes('chart'),
  },
  {
    path: '/icon',
    name: 'icon',
    component: Layout1,
    meta: { auth: true },
    children: iconChildRoute('icon'),
  },
  {
    path: '/',
    name: 'auth1',
    component: AuthLayout1,
    meta: { auth: true },
    children: authChildRoutes('auth1'),
  },
  {
    path: '/auth2',
    name: 'auth2',
    component: AuthLayout2,
    meta: { auth: true },
    children: authChildRoutes2('auth2'),
  },
  {
    path: '/pages',
    name: 'pages',
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes('default'),
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: Layout1,
    meta: { auth: true },
    children: defaultlayout('extra-pages'),
  },
  {
    path: '/app',
    name: 'app',
    component: Layout1,
    meta: { auth: true },
    children: appChildRoute('app'),
  },
  {
    path: '/doctor',
    name: 'doctor',
    component: Layout1,
    meta: { auth: true },
    children: userChildRoute('doctor'),
  },
  {
    path: '/map',
    name: 'map',
    component: Layout1,
    meta: { auth: true },
    children: mapChildRoute('map'),
  },
  {
    path: '/form',
    name: 'form',
    component: Layout1,
    meta: { auth: true },
    children: formChildRoute('form'),
  },
  {
    path: '/plugins',
    name: 'plugins',
    component: Layout1,
    meta: { auth: true },
    children: pluginsChildRoute('plugins'),
  },
  {
    path: '/templates',
    name: 'templates',
    component: Layout1,
    meta: { auth: true },
    children: templatesChildRoutes('templates'),
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { auth: false },
    component: Callback,
  },
  {
    path: '/interview',
    name: 'interview',
    meta: { auth: false },
    component: Interview,
  },  
  {
    path: '/approval-mail',
    name: 'approval-mail',
    meta: { auth: false },
    component: ConfirmationApprovalMail,
  },
  {
    path: '/request-sign-off',
    name: 'request-sign-off',
    meta: { auth: false },
    component: RequestSignOff,
  },
]

const router = new VueRouter({
  mode: 'hash',
  history: false,
  base: process.env.VUE_APP_BASE_URL,
  routes,
})

export default router
